<template>
	<div class="login">
		<div class="logo text-center">
			<a href="https://www.dentroeduca.com.br/" target="_blank"
				><img
					width="127"
					:src="require('@/assets/primary-1.svg')"
					alt="Dentro Educa"
					class
			/></a>
		</div>

		<h3>Área exclusiva para gestor escolar</h3>
		<h5>Faça seu login para acessar a área do gestor escolar.</h5>

		<form @submit.prevent="doLogin" class="va-row">
			<div class="flex xs12">
				<div class="input-group">
					<input-normal
						type="text"
						label="Digite seu e-mail ou CPF "
						id="username"
						name="username"
						ref="username"
						maxlength="250"
						v-validate="'required'"
						:disabled="false"
						:data-vv-as="'usuário'"
						:value="login.username"
						:error="errors.first('username')"
						@input="login.username = $event.target.value"
					/>
				</div>
			</div>
			<div class="flex xs12">
				<div class="input-group">
					<input-normal
						type="password"
						label="Digite sua senha"
						id="password"
						name="password"
						maxlength="50"
						v-validate="'required'"
						:disabled="false"
						:data-vv-as="'senha'"
						:value="login.password"
						:error="errors.first('password')"
						@input="login.password = $event.target.value"
					/>

					<div class="recover-link">
						<router-link :to="{ name: 'recover' }" class="btn btn-micro-edit"
							>Esqueceu a senha?</router-link
						>
					</div>
				</div>

				<div class="error text-center">{{ errors.first("error") }}</div>
			</div>

			<div class="d-flex align--center justify--space-between flex xs12">
				<button id="btn-access" class="btn btn-primary purple" type="submit">
					Entrar
				</button>
			</div>
		</form>
	</div>
</template>

<script>
	import { mask } from "vue-the-mask";
	const axios = require("axios");

	export default {
		name: "login",

		data() {
			return {
				login: {
					username: null,
					password: null,
				},
			};
		},
		mounted() {
			const self = this;

			self.$nextTick(() => {
				this.$refs.username.$refs.input.focus();
			});
		},

		methods: {
			doLogin() {
				const self = this;
				this.errors.clear();

				this.$validator.validateAll().then((result) => {
					if (result) {
						axios
							.post(process.env.VUE_APP_ROOT_API + "/users/login", self.login)
							.then(function (response) {
								if (response.data.data.user.role_id === 25) {
									let principalCredential = {
										token: response.data.data.token,
										project_id: response.data.data.user.project_id,
										role_id: response.data.data.user.role_id,
										user: response.data.data.user,
										settings: response.data.data.settings,
									};

									if (response.data.data.user.is_manager) {
										principalCredential.school_id =
											response.data.data.user.school_id;

										if (principalCredential.project_id.length > 1) {
											principalCredential.currentAdoption = {
												id: principalCredential.project_id[0].id,
												school_id: principalCredential.school_id[0].id,
											};
										} else if (
											Object.keys(principalCredential.project_id).length > 1
										) {
											var principalToTransform = [];

											Object.keys(principalCredential.project_id).forEach(
												function (k) {
													//console.log(i);
													principalToTransform.push(
														principalCredential.project_id[k]
													);
												}
											);

											// for (
											// 	var counterProjects = 1;
											// 	counterProjects <
											// 	Object.keys(principalCredential.project_id).length;
											// 	counterProjects++
											// ) {
											// 	//console.log(i);
											// 	principalToTransform.push(
											// 		principalCredential.project_id[counterProjects]
											// 	);

											// 	// more statements
											// }

											principalCredential.currentAdoption =
												principalToTransform[0];
											// self.currentProjectId = principalToTransform[0].id;
											// school_id = principalToTransform[0].school_id;

											// principalCredential.currentAdoption = {
											// 	id: principalToTransform[0].id,
											// 	school_id: principalToTransform[0].id,
											// };
										} else {
											principalCredential.currentAdoption = {
												id: principalCredential.project_id,
												school_id: principalCredential.project_id.id,
											};
										}

										self.$store.commit(
											"SAVE_PRINCIPAL_CREDENTIALS",
											principalCredential
										);
										self.$router.push({ name: "schedule" });
									} else {
										let fieldError = {
											field: "error",
											msg: error.response.data.message,
											rule: "error", // optional
										};
										self.$validator.errors.add(fieldError);
									}
								} else {
									let fieldError = {
										field: "error",
										msg: "Usuário não correspondente ao perfil",
										rule: "error", // optional
									};
									self.$validator.errors.add(fieldError);
								}
							})
							.catch(function (error) {
								let fieldError = {
									field: "error",
									msg: error.response.data.message,
									rule: "error", // optional
								};
								self.$validator.errors.add(fieldError);
							});
					}
				});
			},
		},
	};
</script>

<style lang="scss">
	.recover-link {
		position: absolute;
		right: 20px;
		top: 35px;
		width: 150px;

		.btn {
			width: 100%;
		}
	}
	.login {
		font-family: "Nunito";
		width: 35rem;
		text-align: center;

		@include media-breakpoint-down(md) {
			width: 100%;
			padding-right: 2rem;
			padding-left: 2rem;
			.down-container {
				display: none;
			}
		}

		h3 {
			text-align: center;
			font-size: 24px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.17;
			letter-spacing: 1.33px;
			text-align: center;
			color: #68596e;
			font-weight: 800;
			margin-bottom: 10px;
		}

		h5 {
			font-size: 18px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #afa2ba;
			margin-bottom: 30px;
		}

		.btn-primary {
			width: 100%;
			//font-family: "Nunito";
			font-size: 14px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 4;
			letter-spacing: 0.8px;
			text-align: center;
			color: #ffffff;
			border-radius: 40px;
			text-transform: uppercase;
			margin-top: 30px;
		}
		.down-container {
			margin-top: 3.125rem;
		}
		.purple {
			background-image: none !important;
			background-color: #923fda !important;
			&:hover {
				background-color: #8339c4 !important;
				transition: 0.5s;
			}
		}
	}
</style>
